import Link from 'next/link';
import styled, { Box } from '@xstyled/styled-components';
import PopularProductSlider from './BestsellerSlider';
import PopularProductSwiper from './BestsellerSwiper';
import Loader from '@/UI/Loader';
import { SectionTitle } from '@/UI/Primitives/Headings';
import { Container } from '@/UI/Layout/Container';
import { FlexBox } from '@/UI/Primitives/FlexBox';
import { useTranslation } from 'next-i18next';

const BestsellersWrapper = styled.section`
  width: 100vw;
  padding: 3rem 1rem;
  background-color: white;

  a {
    display: block;
    text-align: center;
    color: black;
    font-weight: 700;
    text-decoration: underline;

    &:hover {
      cursor: pointer;
      color: teal-400;
    }
  }

  @media (min-width: desktop) {
    padding: 3rem 2rem;
  }
`;

export default function Bestsellers({ bestsellers }) {
  const { t } = useTranslation();

  return (
    <BestsellersWrapper>
      <Container>
        <SectionTitle display={{ _: 'block', desktop: 'none' }} mb="1rem">
        {t('home.popular_products')}
        </SectionTitle>

        <FlexBox
          display={{ _: 'none', desktop: 'flex' }}
          align="center"
          justify="space-between"
          mb="3rem"
        >
          <SectionTitle>{t('home.popular_products')}</SectionTitle>
          <Link href="/kataloog/lapsevankrid-ja-karud">
            <a>{t('home.all_products')} &gt;</a>
          </Link>
        </FlexBox>

        {bestsellers ? (
          <>
            {/* Mobile swiper */}
            <PopularProductSwiper bestsellers={bestsellers} />
            {/* Desktop slider */}
            <PopularProductSlider bestsellers={bestsellers} />
          </>
        ) : (
          <Loader />
        )}

        <Box display={{ _: 'block', desktop: 'none' }} mt="1rem">
          <Link href="/kataloog/vankrid">
            <a>{t('home.all_products')} &gt;</a>
          </Link>
        </Box>
      </Container>
    </BestsellersWrapper>
  );
}
