import SkeletonElement from './SkeletonElement';
import styled from '@xstyled/styled-components';

const SkeletonProductCard = () => {
  return (
    <SkeletonProductBox>
      <SkeletonElement type={'skeletonproductimage'}></SkeletonElement>
      <SkeletonInfo>
        <SkeletonElement type={'skeletontitle'}></SkeletonElement>
        <SkeletonElement type={'skeletontext'}></SkeletonElement>
        <SkeletonElement type={'skeletontext'}></SkeletonElement>
      </SkeletonInfo>
    </SkeletonProductBox>
  );
};

export default SkeletonProductCard;

const SkeletonProductBox = styled.divBox`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  max-width: 270px;
  padding: 0 1.25rem;
  margin-right: 0.5rem;
  border: 0.7px solid #d8d9e5;
  border-radius: 12px;
  background-color: white;
  font-weight: 500;
  height: 369.88px;
  padding: '1rem 1rem 2rem';

  @media (max-width: lg_tablet) {
    border: none;
  }
`;
const SkeletonInfo = styled.div`
  flex-direction: column;
  padding-bottom: 15%;
`;
