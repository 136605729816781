import { useState } from 'react';
import { useRouter } from 'next/router';
import { useLazyQuery } from '@apollo/client';
import { useAtomValue } from 'jotai/utils';
import {
  currentPageAtom,
  filtersAtom,
  sortAtom,
  pageSizeAtom
} from 'global/store';
import { CATEGORY_PRODUCTS } from 'graphql/queries';
import getCategoryId from 'utils/getCategoryId';
import useProductPages from './useProductPages';

export default function useCatalogue() {
  const router = useRouter();
  const lang = router.locale ? router.locale : 'et';
  // Get category url slug
  const categorySlug = router.asPath.split('/').pop().split('?')[0];

  // Get category ID
  const id = getCategoryId(categorySlug);

  const [productCount, setProductCount] = useState(0);
  const pageSize = useAtomValue(pageSizeAtom);

  const currentPage = useAtomValue(currentPageAtom);
  const { value: sortBy } = useAtomValue(sortAtom);

  const {
    price,
    brand,
    // isofix,
    // kaal,
    // karu_kaal,
    // kandevoime,
    // kasutusaeg,
    // laius,
    // laste_jalgrattatoolid,
    // madrats_komplektis,
    // madratsi_moodud,
    // mudel,
    // otstarve,
    // rataste_arv,
    v__rv
  } = useAtomValue(filtersAtom);

  // Get category products
  const [getProducts, { data, loading }] = useLazyQuery(CATEGORY_PRODUCTS, {
    variables: {
      id,
      price,
      brand,
      // isofix,
      // kaal,
      // karu_kaal,
      // kandevoime,
      // kasutusaeg,
      // laius,
      // laste_jalgrattatoolid,
      // madrats_komplektis,
      // madratsi_moodud,
      // mudel,
      // otstarve,
      // rataste_arv,
      v__rv,
      sortBy,
      currentPage,
      pageSize
    },
    context: { headers: { store: lang }, fetchOptions: { method: 'GET' } },
    fetchPolicy: 'network-only',
    onCompleted: data => {

      const count = data?.products?.total_count;
      if (count && productCount !== count) {
        setProductCount(count);
      }
    }
  });

  const { products, pages } = useProductPages(getProducts, data);

  return {
    products,
    loading,
    pages,
    count: productCount
  };
}
