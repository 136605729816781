import { useRouter } from 'next/router';
import Image from 'next/image';
import { SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay, Pagination } from 'swiper';
import Headline from './Headline';
import PrimaryButton from '@/UI/Button/Variants/Primary';
import { SectionTitle } from '@/UI/Primitives/Headings';
import { FlexBox } from '@/UI/Primitives/FlexBox';
import { Container } from '@/UI/Layout/Container';
import {
  BannerBox,
  BannerContainer,
  BannerImage,
  BannerSlider
} from './styles';


import { useTranslation } from 'next-i18next';

SwiperCore.use([Autoplay, Pagination]);

export default function Banner({ items }) {
  const { t } = useTranslation();

  const router = useRouter();

  return (
    <BannerContainer>
      <Container>
        {items && (
          <BannerSlider
            loop
            pagination={items?.length > 1 ? { clickable: true } : false}
            spaceBetween={100}
            autoplay={
              items?.length > 1
                ? {
                    enabled: true,
                    delay: 7000
                  }
                : false
            }
            speed={1200}
          >
            {items?.map(item => {
              return (
                <SwiperSlide key={item.image}>
                  <BannerImage h="min(50vw, 465px)">
                    <Image
                      src={item.image}
                      alt="Banner image"
                      layout="fill"
                      priority={true}
                    />
                  </BannerImage>
                  <FlexBox
                    justify={{ _: 'center', desktop: 'start' }}
                    mb={{ desktop: '5rem' }}
                    mt={{ _: '4rem', desktop: '0rem' }}
                  >
                    
                    <BannerBox>
                      <SectionTitle as="h1">{item.title}</SectionTitle>
                      <p>{item.description}</p>
                      <PrimaryButton
                        mt="2rem"
                        padding="0.5rem 2rem"
                        onClick={() => router.push(item.path)}
                      >
                        {t('banner.button')}
                      </PrimaryButton>
                    </BannerBox>
                  </FlexBox>
                </SwiperSlide>
              );
            })}
          </BannerSlider>
        )}
        <Headline />
      </Container>
    </BannerContainer>
  );
}
