import styled from '@xstyled/styled-components';
import { Swiper } from 'swiper/react';

export const BannerContainer = styled.sectionBox`
  position: relative;
  width: 100vw;
  padding: 2.125rem 1rem;
  margin-top: -1rem;
  background-color: blue-100;

  @media (min-width: desktop) {
    padding: 1.75rem 2rem 1.375rem;
  }
`;

export const BannerBox = styled.divBox`
  position: absolute;
  top: 5rem;
  width: 88%;
  padding: 1.75rem 1.5rem 2rem;
  border-radius: 24px;
  background-color: white;
  z-index: 1;

  @media (min-width: desktop) {
    max-width: 420px;
    padding: 3rem 3.25rem;
    top: 5rem;

    p {
      margin: 1rem 0 0;
    }
  }

  @media (max-width: desktop) {
    position: static;
    transform: translateY(-20%);

    p {
      margin: 2rem 0 0;
    }
  }

  @media (min-width: tablet) and (max-width: desktop) {
    max-width: 420px;
    height: auto;
    padding: 2rem;
    top: 5rem;
  }
`;

export const BannerImage = styled.divBox`
  position: relative;
  width: 100%;
  /* height: min(50vw, 465px); */

  @media (min-width: desktop) {
    left: 15rem;
  }

  @media (min-width: desktop) and (max-width: 1064.98px) {
    width: 720px;
  }

  @media (min-width: 1065px) {
    width: 750px;
  }

  @media (min-width: lg_desktop) {
    width: 890px;
  }

  img {
    border-radius: 12px;
  }
`;

export const BannerSlider = styled(Swiper)`
  @media (min-width: desktop) {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  @media (max-width: desktop) {
    position: initial;
  }

  .swiper-pagination-bullets {
    bottom: 2rem;

    @media (max-width: desktop) {
      bottom: 1rem;
    }
  }

  .swiper-pagination-bullet {
    transform: scale(1.3);
    margin: 0 10px !important;
  }

  .swiper-pagination-bullet-active {
    background-color: teal-300;
  }
`;
