import Link from 'next/link';
import useProductCardPrice from 'hooks/products/useProductCardPrice';
import getProductImage from 'utils/getProductImage';
import DiscountPercentBadge from './DiscountPercentBadge';
import Image from '@/UI/Image';
import ClientOnly from '@/UI/Primitives/ClientOnly';
import AddToCartButton from '@/UI/Button/AddToCart';
import StarRating from '@/UI/Ratings/StarRating';
import {
  ProductCardBox,
  ProductTitle,
  ProductPrice,
  ButtonsContainer
} from './styles';

/**
 * ProductCard
 *
 * Generic product card component
 *
 * @prop {object} product - object of a single product
 * @prop {object} imageSize - object of image width and height {width, height}
 * @prop {object} badges - object of badges to show/hide with custom content
 * @prop {boolean} hideRating - to toggle if rating is hidden
 * @prop {float} scale - image transform scale
 * @prop {float} hoverScale - image transform scale on hover
 */

export default function ProductCard(props) {
  const price = useProductCardPrice(props.product?.price_range);

  const image = getProductImage({
    mainImage: props.product.image,
    gallery: props.product.media_gallery
  });

  return (
    <ProductCardBox {...props} hoverScale={props.hoverScale}>
      <Link
        href="/toode/[toode]"
        as={`/toode/${props.product.url_key}`}
        // onClick={() => onSelect(id)}
      >
        <a>
          {!props.hideRating && props.product?.rating_summary > 0 ? (
            <StarRating
              rating={props.product?.rating_summary}
              flexDirection="column"
              position="absolute"
              right={props.scale ? '0.35rem' : '0.6rem'}
              top={props.scale ? '0.25rem' : '0.5rem'}
            />
          ) : null}
          
          {(price?.discount?.value && props.badges?.discount) ||
          props.badges?.discount.text === 'Lemmik' ||
          props.badges?.discount.text === 'Favourite' ? (
            <span id="discount_badge">
              {props.badges.discount.text || 'Soodus'}
            </span>
          ) : null}
          {price?.discount?.percentNumber > 0 && !props.badges?.discount ? (
            <DiscountPercentBadge
              percent={
                '-' +
                Math.round(
                  Number(
                    price?.discount?.percent
                      ?.replace('-', '')
                      .replace(' ', '')
                      .replace('kuni', '')
                      .replace('%', '')
                  )
                ) +
                '%'
              }
            />
          ) : null}
          <Image
            src={image?.url}
            alt={image?.label}
            width={props.imageSize.width}
            height={props.imageSize.height}
            objectFit="contain"
          />
          <ProductTitle scale={props.scale}>{props.product?.name}</ProductTitle>
          <ProductPrice scale={props.scale}>
            {price?.discount?.value ? (
              <span>{price?.discount?.value}</span>
            ) : null}
            {price?.value}
          </ProductPrice>
        </a>
      </Link>
      <ButtonsContainer spaceX="1rem">
        <ClientOnly>
          <AddToCartButton product={props.product} />
        </ClientOnly>
        {/* <CompareButtonSmall /> */}
      </ButtonsContainer>
    </ProductCardBox>
  );
}
