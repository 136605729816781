import SkeletonElement from './SkeletonElement';
import styled from '@xstyled/styled-components';

const SkeletonSmallCard = () => {
  return (
    <SkeletonProductBox>
      <SkeletonElement type={'skeletonproductimage'}></SkeletonElement>
      <SkeletonElement type={'skeletontitle'}></SkeletonElement>
      <SkeletonElement type={'skeletontext'}></SkeletonElement>
      <SkeletonElement type={'skeletontext'}></SkeletonElement>
    </SkeletonProductBox>
  );
};

export default SkeletonSmallCard;

const SkeletonProductBox = styled.divBox`
  position: relative;
  height: 100%;
  width: 200px;
  height: 250px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 2.5rem 1.25rem 1rem;
  margin-right: 0.5rem;
  /* border: 0.7px solid #d8d9e5; */
  border-radius: 12px;
  background-color: white;
`;
