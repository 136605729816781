// import { useTranslation } from 'next-i18next';
import { Box } from '@xstyled/styled-components';
import { FlexBox } from '@/UI/Primitives/FlexBox';
import { Inventory, Transport, Refund, Safety } from '@/UI/Icons/Banner';

import { useTranslation } from 'next-i18next';

const HeadlineItem = ({ mr, title, icon, index }) => {

  return (
    <FlexBox lineHeight="1">
      <Box
        as="span"
        role="img"
        aria-label="Headline icon"
        w="32px"
        h="32px"
        mr={mr}
      >
        {icon}
      </Box>
      <Box as="p" maxW={index === 1 ? 200 : index === 4 ? 100 : 150}>
        {title}
      </Box>
    </FlexBox>
  );
};

export default function Headline() {
   const { t } = useTranslation();

  return (
    <FlexBox
      display={{ _: 'none', desktop: 'flex' }}
      justify="center"
      mb="3rem"
      spaceX="5rem"
    >
      <HeadlineItem
        mr="1rem"
        title={t('banner.headline1')}
        icon={Inventory}
        index={1}
      />
      <HeadlineItem
        mr="1.7rem"
        title={t('banner.headline2')}
        icon={Transport}
        index={2}
      />
      <HeadlineItem
        mr="1.2rem"
        title={t('banner.headline3')}
        icon={Refund}
        index={3}
      />
      <HeadlineItem
        mr="0.8rem"
        title={t('banner.headline4')}
        icon={Safety}
        index={4}
      />
    </FlexBox>
  );
}
