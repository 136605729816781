import { useEffect, useState } from 'react';
import { useAtom } from 'jotai';
import { currentPageAtom } from 'global/store';
import { pageSizeAtom } from 'global/store';

export default function useProductPages(getProducts, data) {
  const [products, setProducts] = useState(null);
  const [pages, setPages] = useState(null);

  const [currentPage, setCurrentPage] = useAtom(currentPageAtom);

  // Execute products query upon page change
  useEffect(() => getProducts(), [currentPage]);

  // Set products & pages
  useEffect(() => {
    if (data?.products) {
      setProducts(data?.products.items);

      const pageAmount = data?.products?.page_info?.total_pages;
      if (pages !== pageAmount) {
        setPages(pageAmount);
      }
    }
  }, [data?.products]);

  return { products, pages };
}
