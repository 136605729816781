import Link from 'next/link';
import styled from '@xstyled/styled-components';
import BlogEntry from './Entry';
import { SectionTitle } from '@/UI/Primitives/Headings';
import { Container } from '@/UI/Layout/Container';

const BlogGrid = styled.div`
  @media (min-width: desktop) {
    display: grid;
    grid-template-columns: ${({ cols }) => cols};
    grid-column-gap: 1rem;
  }

  margin-bottom: 1rem;
`;

const BlogContainer = styled.section`
  width: 100%;
  padding: 2rem 1rem 3rem;
  background-color: white;

  ${BlogGrid}:nth-of-type(1) {
    margin-top: 3rem;

    @media (max-width: desktop) {
      margin-top: 2.625rem;
    }
  }

  @media (min-width: desktop) {
    padding: 4rem 2rem 3rem;

    ${BlogGrid}:nth-of-type(2) {
      margin-bottom: 2rem;
    }
  }
`;

export default function Blog({ blogPosts }) {
  return (
    <Container>
      <BlogContainer>
        <Link href="/blogi">
          <a>
            <SectionTitle hoverOpacity="0.7">Blogi</SectionTitle>
          </a>
        </Link>
        <BlogGrid cols="1.5fr 1fr">
          {blogPosts?.slice(0, 2).map(post => {
            return <BlogEntry key={post.identifier} post={post} />;
          })}
        </BlogGrid>
        <BlogGrid cols="1fr 1.5fr">
          {blogPosts?.slice(2, 4).map(post => {
            return <BlogEntry key={post.identifier} post={post} />;
          })}
        </BlogGrid>
      </BlogContainer>
    </Container>
  );
}
