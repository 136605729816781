// Inventory icon for homepage banner headline

export const Inventory = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 32 32"
  >
    <g
      id="Group_391"
      data-name="Group 391"
      transform="translate(-46.709 -19.709)"
    >
      <rect
        id="Rectangle_299"
        data-name="Rectangle 299"
        width="32"
        height="32"
        rx="2"
        transform="translate(46.709 19.709)"
        fill="#a8e5e6"
      />
      <path
        id="Path_246"
        data-name="Path 246"
        d="M65.684,20V35.854l-6.342-6.342L53,35.854V20Z"
        transform="translate(3.513 -0.291)"
        fill="#ff7058"
      />
      <path
        id="Path_247"
        data-name="Path 247"
        d="M57.22,35.085H49.293a.793.793,0,0,1,0-1.585H57.22a.793.793,0,0,1,0,1.585Z"
        transform="translate(0.878 7.903)"
        fill="#334a5e"
      />
      <path
        id="Path_248"
        data-name="Path 248"
        d="M61.976,38.085H49.293a.793.793,0,0,1,0-1.585H61.976a.793.793,0,1,1,0,1.585Z"
        transform="translate(0.878 9.66)"
        fill="#334a5e"
      />
    </g>
  </svg>
);

// Transportation icon for homepage banner headline

export const Transport = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="45.703"
    height="33.457"
    viewBox="0 0 45.703 33.457"
  >
    <g id="delivery-truck" transform="translate(0 -68.597)">
      <path
        id="Path_211"
        data-name="Path 211"
        d="M340.146,164.8h-9.437v17.1H346.17v-7.7l-1.8-6.232a4.395,4.395,0,0,0-4.221-3.173"
        transform="translate(-301.192 -87.616)"
        fill="#ff7058"
      />
      <path
        id="Path_212"
        data-name="Path 212"
        d="M365.829,196.043l-1.607-5.555a2.261,2.261,0,0,0-2.16-1.623h-7.289v7.179Z"
        transform="translate(-323.108 -109.533)"
        fill="#fff"
      />
      <path
        id="Path_215"
        data-name="Path 215"
        d="M2.856,68.6H26.661a2.857,2.857,0,0,1,2.856,2.856V94.286H0V71.453A2.856,2.856,0,0,1,2.856,68.6"
        fill="#a8e5e6"
      />
      <path
        id="Path_217"
        data-name="Path 217"
        d="M45.742,322.209v3.227H.043v-3.227h4.77a6.544,6.544,0,0,1,11.425,0H31.585a6.535,6.535,0,0,1,11.414,0Z"
        transform="translate(-0.039 -227.924)"
        fill="#334a5e"
      />
      <path
        id="Path_218"
        data-name="Path 218"
        d="M375.35,345.8a4.56,4.56,0,1,1-4.561-4.561,4.561,4.561,0,0,1,4.561,4.561"
        transform="translate(-333.541 -248.305)"
        fill="#40596b"
      />
      <path
        id="Path_220"
        data-name="Path 220"
        d="M75.459,345.8a4.561,4.561,0,1,1-4.561-4.561,4.562,4.562,0,0,1,4.561,4.561"
        transform="translate(-60.415 -248.305)"
        fill="#40596b"
      />
    </g>
  </svg>
);

// Refund icon for homepage banner headline

export const Refund = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="41.318"
    height="43.444"
    viewBox="0 0 41.318 43.444"
  >
    <g
      id="Group_333"
      data-name="Group 333"
      transform="translate(-63.5 -65.419)"
    >
      <rect
        id="Rectangle_289"
        data-name="Rectangle 289"
        width="19.675"
        height="19.675"
        rx="0.876"
        transform="translate(74.321 78.367)"
        fill="#a8e5e6"
      />
      <path
        id="Path_226"
        data-name="Path 226"
        d="M79.87,72v9.838L75.935,77.9,72,81.838V72Z"
        transform="translate(8.224 6.367)"
        fill="#ff7058"
      />
      <path
        id="Path_227"
        data-name="Path 227"
        d="M75.4,80.468H70.484a.984.984,0,0,1,0-1.968H75.4a.984.984,0,0,1,0,1.968Z"
        transform="translate(5.805 12.656)"
        fill="#334a5e"
      />
      <path
        id="Path_228"
        data-name="Path 228"
        d="M78.354,81.968h-7.87a.984.984,0,0,1,0-1.968h7.87a.984.984,0,1,1,0,1.968Z"
        transform="translate(5.805 14.107)"
        fill="#334a5e"
      />
      <g
        id="Group_330"
        data-name="Group 330"
        transform="translate(63.5 65.419)"
      >
        <path
          id="Path_229"
          data-name="Path 229"
          d="M84.159,107.757A20.682,20.682,0,0,1,63.5,87.1a.984.984,0,1,1,1.968,0A18.691,18.691,0,1,0,86.294,68.527a.981.981,0,0,1-.864-1.088.956.956,0,0,1,1.088-.866,20.66,20.66,0,0,1-2.359,41.184Z"
          transform="translate(-63.5 -64.313)"
          fill="#334a5e"
        />
        <g id="Group_329" data-name="Group 329" transform="translate(20.659)">
          <path
            id="Path_230"
            data-name="Path 230"
            d="M80.373,65.6l-3.549,3.11,3.112,3.547-2.826-.186L74,68.529l3.547-3.11Z"
            transform="translate(-74 -65.419)"
            fill="#334a5e"
          />
        </g>
      </g>
    </g>
  </svg>
);

// Safety guarantee icon for homepage banner headline

export const Safety = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="31.908"
    height="39.354"
    viewBox="0 0 31.908 39.354"
  >
    <g
      id="Group_332"
      data-name="Group 332"
      transform="translate(-103.889 -20.5)"
    >
      <path
        id="Path_231"
        data-name="Path 231"
        d="M119.716,48.154h-4.254a9.583,9.583,0,0,1-9.573-9.573V30.073a9.583,9.583,0,0,1,9.573-9.573h4.254a9.584,9.584,0,0,1,9.573,9.573v8.509A9.584,9.584,0,0,1,119.716,48.154Zm-4.254-25.527a7.453,7.453,0,0,0-7.445,7.445v8.509a7.453,7.453,0,0,0,7.445,7.445h4.254a7.455,7.455,0,0,0,7.445-7.445V30.073a7.455,7.455,0,0,0-7.445-7.445Z"
        transform="translate(2.255 0)"
        fill="#334a5e"
      />
      <rect
        id="Rectangle_290"
        data-name="Rectangle 290"
        width="31.908"
        height="27.654"
        rx="2.04"
        transform="translate(103.889 32.2)"
        fill="#a8e5e6"
      />
      <path
        id="Path_232"
        data-name="Path 232"
        d="M116.271,33.191a3.191,3.191,0,1,0-5.312,2.38.69.69,0,0,1,.238.647l-1.149,5.746a.669.669,0,0,0,.655.8h4.75a.669.669,0,0,0,.657-.8l-1.151-5.746a.7.7,0,0,1,.24-.647A3.179,3.179,0,0,0,116.271,33.191Z"
        transform="translate(6.763 10.709)"
        fill="#ff7058"
      />
    </g>
  </svg>
);
