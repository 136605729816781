import Link from 'next/link';
import Image from 'next/image';
import styled from '@xstyled/styled-components';
import { Container } from '@/UI/Layout/Container';
import { useTranslation } from 'next-i18next';


const BrandContainer = styled.section`
  padding: 0 3.5rem 6rem;
  background-color: white;

  ul {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-row-gap: 1rem;

    @media (min-width: desktop) {
      padding: 4rem 4.5rem 6rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    + a {
      display: block;
      text-align: center;
      color: black;
      font-weight: 700;
      text-decoration: none;

      &:hover {
        cursor: pointer;
        color: teal-400;
      }

      @media (max-width: desktop) {
        margin-top: 2rem;
      }
    }
  }
`;

export default function FeaturedBrands({brands}) {
  const { t } = useTranslation();

  return (
    <Container>
      <BrandContainer>
        <ul>
          {brands?.map(brand => {
            return (
              <li key={brand.option_id}>
                <Link href={`/brands/${brand.url_key}?id=${brand.option_id}`}>
                  <a>
                    <Image
                      src={brand.image}
                      alt={brand.value}
                      width={100}
                      height={100}
                      objectFit="contain"
                    />
                  </a>
                </Link>
              </li>
            );
          })}
        </ul>
        <Link href="/brands">
          <a>{t('home.see_all_brands')} &gt;</a>
        </Link>
      </BrandContainer>
    </Container>
  );
}
