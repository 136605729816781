import { Box } from '@xstyled/styled-components';

export default function CarouselArrow({ onClick, children }) {
  return (
    <Box
      as="span"
      role="button"
      aria-label="Arrow for toggling between slides"
      onClick={onClick}
    >
      {children}
    </Box>
  );
}
