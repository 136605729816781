import Script from 'next/script';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { apolloClient, apolloClientSSR } from 'graphql/apollo-client';
import {
  CMS_BLOCK,
  BESTSELLERS,
  CATEGORIES,
  BLOG_POSTS_FRONT,
  FEATURED_BRANDS,
  BESTSELLERS_SECOND,
  STATIC_PAGE
} from 'graphql/queries';
import Layout from '@/UI/Layout';
import Banner from '@/Banner';
import Bestsellers from '@/Home/Bestsellers';
import Blog from '@/Home/Blog';
import Brands from '@/Home/Brands';
import SEO from '@/SEO';

import dynamic from 'next/dynamic';

const Categories = dynamic(() => import('@/Home/Categories'));

export default function Home({
  bannerItems,
  categories,
  bestsellers,
  blogPosts,
  brands,
  meta
}) {
  // Restore scroll position when returning from a page
  // useScrollRestoration({ data: bannerItems });
  return (
    <Layout categories={categories}>
      <SEO
        seo={{
          title: meta.title ? meta.title : `Baltbaby`,
          description: meta.description
            ? meta.description
            : 'Baltbaby on baltikumi suurim beebitoodete e-kaubamaja. Baltbaby tootevalikust leiad suures koguses beebiriideid, vankreid ja muud beebi- ja lastekaupa.'
        }}
      />
      <Script
        type="application/ld+json"
        id="baltbaby_sitelinks_structured_data"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify({
            '@context': 'https://schema.org',
            '@type': 'WebSite',
            url: 'https://baltbaby.com',
            potentialAction: {
              '@type': 'SearchAction',
              target: 'https://baltbaby.com/otsing/{search_term_string}',
              'query-input': 'required name=search_term_string'
            }
          })
        }}
      />
      <Banner items={bannerItems} />
      <Bestsellers bestsellers={bestsellers} />
      <Categories categories={categories} />
      {blogPosts.length > 0 && <Blog blogPosts={blogPosts} />}
      <Brands brands={brands} />
    </Layout>
  );
}

export async function getServerSideProps(context) {
  const locale = context.locale;
  const cookie = context.req.headers?.cookie;
  const query = (query, variables) => {
    return apolloClientSSR(locale, cookie).query({
      query,
      variables,
      context: {
        fetchOptions: { method: 'GET' }
      }
    });
  };
  const queryET = (query, variables) => {
    return apolloClientSSR('et', cookie).query({
      query,
      variables,
      context: {
        fetchOptions: { method: 'GET' }
      }
    });
  };
  //BANNER
  const identifier = locale === 'lt' ? 'main-banner-lt' : 'main-banner';

  const bannerPromise = query(CMS_BLOCK, { identifier });

  const pagePromise = query(STATIC_PAGE, { identifier: 'home' });

  //CATEGORIES
  const categoriesPromise = query(CATEGORIES, { ids: '2' });
  //BESTSELLERS
  const bestSellersPromise = query(BESTSELLERS);

  //BLOG
  const blogPromise = query(BLOG_POSTS_FRONT);
  //BRANDS
  const brandsPromise = queryET(FEATURED_BRANDS);

  const [banner, categories, bestsellers, blogPosts, brands, page] =
    await Promise.all([
      bannerPromise,
      categoriesPromise,
      bestSellersPromise,
      blogPromise,
      brandsPromise,
      pagePromise
    ]);

  //Initially queried by attribute kuva_avalehel if less than 12 of those then merge with additional products for bestsellers
  let bestSelling = Object.assign([], bestsellers.data.products.items);
  let bestSellingSecond = [];

  if (bestSelling < 12) {
    const bestSellersSecond = await query(BESTSELLERS_SECOND);
    if (bestSellersSecond?.data?.products?.items?.length > 0) {
      bestSellingSecond = Object.assign(
        [],
        bestSellersSecond?.data?.products?.items
      );
    }
  }
  const ids = new Set(bestSelling.map(d => d.sku));
  const mergedBestSellers = [
    ...bestSelling,
    ...bestSellingSecond.filter(d => !ids.has(d.sku))
  ];

  const bannerItems = banner?.data?.cmsBlocks?.items;
  let parsedBanner = [];
  try {
    parsedBanner = JSON.parse(
      bannerItems[0]?.content.replace(/<(.|\n)*?>/g, '')
    );
  } catch {
    parsedBanner = bannerItems[0]?.content.replace(/<(.|\n)*?>/g, '');
  }
  return {
    props: {
      bannerItems: parsedBanner,
      categories: categories.data.categoryList
        ? categories.data.categoryList[0].children
        : [],
      bestsellers: mergedBestSellers,
      blogPosts: blogPosts.data.blogPosts.items,
      brands: brands.data.mpbrand.items,
      meta: {
        title: page?.data?.cmsPage?.meta_title
          ? page?.data?.cmsPage?.meta_title
          : '',
        description: page?.data?.cmsPage?.meta_description
          ? page?.data?.cmsPage?.meta_description
          : ''
      },

      ...(await serverSideTranslations(locale))
    }
    //revalidate: 100 // seconds
  };
}
