import { useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import styled from '@xstyled/styled-components';
import ProductCard from '@/UI/ProductCard';
import CarouselArrow from '@/UI/Primitives/CarouselArrow';
import { SliderArrowRight, SliderArrowLeft } from '@/UI/Icons';
import SkeletonProductCard from '@/UI/Skeletons/SkeletonProductCard';
import useCatalogue from 'hooks/products/useCatalogue';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
const SliderContainer = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: desktop) {
    display: none;
  }
`;

const Arrow = styled.div`
  cursor: pointer;
  position: relative;
  bottom: 1rem;
  right: ${({ prev }) => prev && '1.5rem'};
  left: ${({ next }) => next && '0.75rem'};

  @media (min-width: desktop) and (max-width: lg_desktop) {
    right: ${({ prev }) => prev && '0.75rem'};
    left: ${({ next }) => next && '0.2rem'};
  }
`;

const StyledSwiper = styled(Swiper)`
  padding-bottom: 3rem;
`;

export default function PopularProductSlider({ bestsellers }) {
  const swiperRef = useRef(null);
  const numArray = [...Array(4)];
  const { loading } = useCatalogue();
  const router = useRouter();
  const lang = router.locale ? router.locale : 'et';

  return (
    <SliderContainer>
      <Arrow prev>
        <CarouselArrow onClick={() => swiperRef.current.swiper.slidePrev()}>
          <SliderArrowLeft strokeWidth={6} />
        </CarouselArrow>
      </Arrow>
      <>
        {loading ? (
          <StyledSwiper
            ref={swiperRef}
            spaceBetween={14}
            breakpoints={{
              1024: {
                slidesPerView: 3,
                spaceBetween: 1
              },
              1280: {
                slidesPerView: 4
              }
            }}
            loop
          >
            {numArray?.map((_, i) => {
              return (
                <SwiperSlide key={i}>
                  <SkeletonProductCard />
                </SwiperSlide>
              );
            })}
          </StyledSwiper>
        ) : (
          <StyledSwiper
            ref={swiperRef}
            spaceBetween={14}
            breakpoints={{
              1024: {
                slidesPerView: 3,
                spaceBetween: 1
              },
              1280: {
                slidesPerView: 4
              }
            }}
            loop
          >
            {bestsellers?.map((product, i) => {
              return (
                <SwiperSlide key={product.name}>

                  <ProductCard
                    hideRating
                    badges={{
                      discount: {
                        text: lang === 'en' ? 'Favourite' : 'Lemmik'
                      }
                    }}
                    product={product}
                    w={{ lg_desktop: 260 }}
                    priority={i <= 4 ? true : false}
                    imageSize={{ width: 1000, height: 1040 }}
                  />
                </SwiperSlide>
              );
            })}
          </StyledSwiper>
        )}
      </>

      <Arrow next>
        <CarouselArrow onClick={() => swiperRef.current.swiper.slideNext()}>
          <SliderArrowRight strokeWidth={6} />
        </CarouselArrow>
      </Arrow>
    </SliderContainer>
  );
}
