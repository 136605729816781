import SwiperCore, { Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import styled, { useViewportWidth } from '@xstyled/styled-components';
import ProductCard from '@/UI/ProductCard';
import useCatalogue from 'hooks/products/useCatalogue';
import SkeletonSmallCard from '@/UI/Skeletons/SkeletonSmallCard';


import { useRouter } from 'next/router';
SwiperCore.use([Pagination]);

const Container = styled.div`
  .swiper-slide div {
    margin: ${({ viewportWidth }) =>
      viewportWidth <= 375 ? '0 auto' : 'unset'};
  }
`;

const StyledSwiper = styled(Swiper)`
  padding-bottom: 3rem;

  .swiper-pagination {
    margin-left: -1rem;
  }

  .swiper-pagination-bullet-active {
    background-color: teal-300;
  }

  @media (min-width: desktop) {
    display: none;
  }
`;

export default function PopularProductSwiper({ bestsellers }) {
  const viewportWidth = useViewportWidth();
  const { loading } = useCatalogue();
  const numArray = [...Array(9)];
  const router = useRouter();
  const lang = router.locale ? router.locale : 'et';

  return (
    <Container viewportWidth={viewportWidth}>
      <StyledSwiper
        loop
        spaceBetween={100}
        breakpoints={{
          0: { slidesPerView: 1, spaceBetween: 0 },
          350: { slidesPerView: 2, spaceBetween: 80 },
          450: { slidesPerView: 2, spaceBetween: 0 },
          560: { slidesPerView: 3, spaceBetween: 80 },
          650: { slidesPerView: 3, spaceBetween: 0 },
          760: { slidesPerView: 4, spaceBetween: 80 },
          850: { slidesPerView: 4, spaceBetween: 0 },
          992: { slidesPerView: 5, spaceBetween: 80 }
        }}
        pagination={{ dynamicBullets: true, dynamicMainBullets: 3 }}
      >
        {loading ? (
          <>
            {numArray.map((_, i) => {
              return (
                <SwiperSlide key={i}>
                  <SkeletonSmallCard />
                </SwiperSlide>
              );
            })}
          </>
        ) : (
          <>
            {bestsellers?.map((product, i) => {
              return (
                <SwiperSlide key={i}>
                  <ProductCard
                    hideRating
                    badges={{
                      discount: {
                        text: lang === 'en' ? 'Favourite' : 'Lemmik'
                      }
                    }}
                    product={product}
                    w={200}
                    priority={i <= 2 ? true : false}
                    imageSize={{ width: 1000, height: 1040 }}
                  />
                </SwiperSlide>
              );
            })}
          </>
        )}
      </StyledSwiper>
    </Container>
  );
}
