import Link from 'next/link';
import styled, { Box, useTheme } from '@xstyled/styled-components';
import Image from '@/UI/Image';
import { SectionTitle } from '@/UI/Primitives/Headings';

const EntryContainer = styled.div`
  a:first-child {
    display: block;
    position: relative;
    width: 100%;
    height: 300px;

    img {
      border-radius: 12px;

      &:hover {
        filter: brightness(0.9) contrast(105%);
        transition: filter 0.2s;
      }
    }
  }

  p {
    padding-bottom: 0.5rem;
    color: gray-100;
    letter-spacing: 0.7;
    font-size: 14px;
    text-transform: uppercase;
  }
`;

/**
 * BlogEntry
 *
 * A card-like component rendering a single blog entry on the homepage
 *
 * @prop {object} post - blog post object { categories, title, image, path }
 */

export default function BlogEntry({ post }) {
  const theme = useTheme();

  const category = post?.categories[0]?.title;
  const postPath = `/blogi/${post.identifier}`;

  return (
    <EntryContainer>
      <Link href={postPath}>
        <a>
          {post.featured_image && (
            <Image src={post.featured_image} alt={post.title} layout="fill" />
          )}
        </a>
      </Link>
      <Box p="2rem 0.25rem 2.875rem">
        <p>{category}</p>
        <Link href={postPath}>
          <a>
            <SectionTitle
              maxW={600}
              fontSize={theme.fontSizes.medium}
              lineHeight="1.5"
              hoverOpacity="0.7"
            >
              {post.title}
            </SectionTitle>
          </a>
        </Link>
      </Box>
    </EntryContainer>
  );
}
